import { createContext } from "react";
import swal from "sweetalert";



// // ****************** Test configration start **************************

// export const apiUrl = "https://test-api.betfolio.co/api/v1"
// export const baseUrl = "https://test-api.betfolio.co/"
// export const tokenAddress = "0x5E6168E0B90Ec559CF0376c4BFDCa4a5689Ae943"; //testnet
// export const feedWalletAddress = "0x7c4db95D43854B3b1bE0ACD53e34146C9451c417";

// export const folioTokenAddress = '0x774946f2D38A5c1F98FD47ebf7cDb8b0dDd21FDb';
// export const feedTokenWallet = "0x1235f3f690337928627D0f4119206767c7FdceB1";
// export const fundTransferContract = "0x6eDeA791982D0570b325b9366999492C94b5B5Ff";
// export const fundTransferDiamondContract = "0xb2eb9453BcF0dCb86d926160Bce620f6B58257A0";
// export const fundTransferFolioContract = "0x6eDeA791982D0570b325b9366999492C94b5B5Ff";
// export const polygonRpcUrl = "https://eth-sepolia.g.alchemy.com/v2/ZbBgRPLIBdkabBTDqNGXe03I5Lh_pYmO"
// export const fundWalletAddress = "0x1235f3f690337928627D0f4119206767c7FdceB1";
// ****************** Test configration ends ***************

// ****************** staging configration start **************************
export const apiUrl = "https://betfolio-api.ecomempire.in/api/v1"
export const baseUrl = "https://betfolio-api.ecomempire.in/"
export const tokenAddress = "0x04BcEfE854Bcfee99d6921249db6F83090b5B2b8";  // staging usdc6
export const feedWalletAddress = "0x7c4db95D43854B3b1bE0ACD53e34146C9451c417";

export const folioTokenAddress = '0x85114f24b621157FFd5b4ad9CEbB60333E7E1da4';
export const feedTokenWallet = "0x1235f3f690337928627D0f4119206767c7FdceB1";
export const fundTransferContract = "0x2269bFC76c433066B893e2248222d6D82176Ecfb";
export const fundTransferDiamondContract = "0xe244652fef5bf57a95d39abd9a9b41dfe959e15f";
export const fundTransferFolioContract = "0x4103a2c36bf268dD70e04De4Da8061b9499A94CF";
export const polygonRpcUrl = "https://polygon-mainnet.g.alchemy.com/v2/7yxaxXwiBlHGtpDrsUZzZJOJHN1dR2yf";
export const fundWalletAddress = "0x1235f3f690337928627D0f4119206767c7FdceB1";
export const platformEarningsAddress = "0x838D6b731eC2a8A49927F6c25Ab22Fc10717020D";
export const NftMintContractAddress="0xc6ba950917be27d13e31ada1a42c262ff73077ad";
export const PredictionLaunchContractAddress ="0x9B9fc4273b1B892b54C0CEb8D805709Cd2C92a5b"
export const DeclareContractAddress ="0xDf6cD0433376533CAd8d150881f24C9451adb346"
// ****************** staging configration ends ***************

// ****************** live configration start **************************
// export const apiUrl = "https://api.betfolio.co/api/v1";
// export const baseUrl = "https://api.betfolio.co/";
// export const tokenAddress = "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359";  // live
// export const feedWalletAddress = "0x9B9fc4273b1B892b54C0CEb8D805709Cd2C92a5b";
// export const folioTokenAddress = '0x2877bc2369a42634becb2d9a4dcf77d97a7230c4';
// export const feedTokenWallet = "0xc671D3387f4264C00dfE8e42403b2a09D97AaFB9";
// export const fundTransferContract = "0x8237c94A5cDFd52e411f60C960f350Af45747BF1";
// export const fundTransferDiamondContract = "0x4bfE755696D0EB90b0e85a194684417387c90E9e";
// export const fundTransferFolioContract = "0xF878335e3eaE629244bAA7C8E38dED0be40440E6";
// export const polygonRpcUrl = "https://polygon-mainnet.g.alchemy.com/v2/7yxaxXwiBlHGtpDrsUZzZJOJHN1dR2yf"
// export const fundWalletAddress = "0x6aE975250135d54991c5fa5c3c1167EEBEAD53B3";
// export const NftMintContractAddress="0xc6ba950917be27d13e31ada1a42c262ff73077ad";

// export const PredictionLaunchContractAddress ="0x9B9fc4273b1B892b54C0CEb8D805709Cd2C92a5b"
// export const DeclareContractAddress ="0xDf6cD0433376533CAd8d150881f24C9451adb346"
// export const platformEarningsAddress = "0x838D6b731eC2a8A49927F6c25Ab22Fc10717020D";

// ****************** live configration ends ***************

export const MyContext = createContext();

export const USDC_DECIMALS = 6;
export const FOLIO_DECIMALS = 18;

export const Unauthorized = () => {
  swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
    localStorage.clear();
    window.location.href = "/";
    console.log("OK button clicked after error alert");
  });
};
export const dateFormate = "DD/MM/YYYY hh:mm A";
export const formatBalance = (rawBalance) => {
  if (isNaN(rawBalance) || rawBalance === null || rawBalance === undefined) {
    return ""; // Return "0.00" if rawBalance is NaN, null, or undefined
  }
  
  const balance = (rawBalance / 1000000).toFixed(2);
  return balance;
};


export const formatNumber = (num) => {
  let formattedNumber = num.toFixed(6);
  // Remove trailing zeros and decimal point if all zeros
  formattedNumber = formattedNumber.replace(/\.?0*$/, '');
  return formattedNumber;
}


export const ITEMS_PER_PAGE = 10;

export const formatWithDecimals = (number, decimals) => {
  decimals = decimals ? decimals : 6
  const divider = 10 ** decimals;
  let balance = number / divider;
  return formatNumber(balance);
}

/* for folio update when we are avaliable */

export const formatWithDecimalsFolio = (number, decimals) => {
  decimals = decimals ? decimals : FOLIO_DECIMALS
  const divider = 10 ** decimals;
  let balance = number / divider;
  return formatNumber(balance);
}


export const numberToLocaleString = (amount) => {
  // console.log("amount",amount);
  return Number(amount).toLocaleString('fullwide', { useGrouping: false })
}

// export const transactionurl =  "https://mumbai.polygonscan.com/tx/"
export const transactionurl = "https://polygonscan.com/tx/";

export const thresholdTimeFram = [
  { value: 2, label: "2 Mins" },
  { value: 240, label: "4 Hours" },
  { value: 360, label: "6 Hours" },
  { value: 720, label: "12 Hours" },
  { value: 1440, label: "24 Hours" },
  { value: 2880, label: "48 Hours" },
  { value: 4320, label: "72 Hours" },
  { value: 10080, label: "1 Week" },
  { value: 20160, label: "2 Weeks" },
];

export const formatWalletAddress = (address) => {
  if (!address) return '';
  const firstPart = address.slice(0, 4);
  const lastPart = address.slice(-4);
  return `${firstPart}...${lastPart}`;
};


export const formatNumberNotation = (num, isPolIncluded = true) => {
  const absNum = Math.abs(num);
  if (absNum >= 1.0e303) {
    return (absNum / 1.0e303).toFixed(1).replace(/\.0$/, '') + "Centillion"; // Centillion
  }
  else if (absNum >= 1.0e63) {
    return (absNum / 1.0e63).toFixed(1).replace(/\.0$/, '') + "Vigintillion"; // Vigintillion
  } else if (absNum >= 1.0e60) {
    return (absNum / 1.0e60).toFixed(1).replace(/\.0$/, '') + "Novemdecillion"; // Novemdecillion
  } else if (absNum >= 1.0e57) {
    return (absNum / 1.0e57).toFixed(1).replace(/\.0$/, '') + "Octodecillion"; // Octodecillion
  } else if (absNum >= 1.0e54) {
    return (absNum / 1.0e54).toFixed(1).replace(/\.0$/, '') + "Septendecillion"; // Septendecillion
  } else if (absNum >= 1.0e51) {
    return (absNum / 1.0e51).toFixed(1).replace(/\.0$/, '') + "Sexdecillion"; // Sexdecillion
  } else if (absNum >= 1.0e48) {
    return (absNum / 1.0e48).toFixed(1).replace(/\.0$/, '') + "Quindecillion"; // Quindecillion
  } else if (absNum >= 1.0e45) {
    return (absNum / 1.0e45).toFixed(1).replace(/\.0$/, '') + "Quattuordecillion"; // Quattuordecillion
  } else if (absNum >= 1.0e42) {
    return (absNum / 1.0e42).toFixed(1).replace(/\.0$/, '') + "Tredecillion"; // Tredecillion
  } else if (absNum >= 1.0e39) {
    return (absNum / 1.0e39).toFixed(1).replace(/\.0$/, '') + "Duodecillion"; // Duodecillion
  } else if (absNum >= 1.0e36) {
    return (absNum / 1.0e36).toFixed(1).replace(/\.0$/, '') + "Undecillion"; // Undecillion
  } else if (absNum >= 1.0e33) {
    return (absNum / 1.0e33).toFixed(1).replace(/\.0$/, '') + "Decillion"; // Decillion
  } else if (absNum >= 1.0e30) {
    return (absNum / 1.0e30).toFixed(1).replace(/\.0$/, '') + "Nonillion"; // Nonillion
  } else if (absNum >= 1.0e27) {
    return (absNum / 1.0e27).toFixed(1).replace(/\.0$/, '') + "Octillion"; // Octillion
  } else if (absNum >= 1.0e24) {
    return (absNum / 1.0e24).toFixed(1).replace(/\.0$/, '') + "Septillion"; // Septillion
  } else if (absNum >= 1.0e21) {
    return (absNum / 1.0e21).toFixed(1).replace(/\.0$/, '') + "Sextillion"; // Sextillion
  } else if (absNum >= 1.0e18) {
    return (absNum / 1.0e18).toFixed(1).replace(/\.0$/, '') + "Quintillions"; // Quintillions
  } else if (absNum >= 1.0e15) {
    return (absNum / 1.0e18).toFixed(1).replace(/\.0$/, '') + "Quadrillions"; // Quadrillions
  } else if (absNum >= 1.0e12) {
    return (absNum / 1.0e12).toFixed(1).replace(/\.0$/, '') + "T";  // Trillions
  } else if (absNum >= 1.0e9) {
    return (absNum / 1.0e9).toFixed(1).replace(/\.0$/, '') + "B";   // Billions
  } else if (absNum >= 1.0e6) {
    return (absNum / 1.0e6).toFixed(1).replace(/\.0$/, '') + "M";   // Millions
  } else if (absNum >= 1.0e3) {
    return (absNum / 1.0e3).toFixed(1).replace(/\.0$/, '') + "K";   // Thousands
  } else {
    return num.toString();                                          // Smaller numbers
  }
};


export function getImageUrl(imageUrl) {
  return imageUrl.includes("lh3.googleusercontent.com") ? imageUrl : baseUrl + imageUrl;
}