import axios from "axios";
import { getItem } from "../util/localStorage";
import { apiUrl, tokenAddress } from "../config/config";
import { duration } from "moment";
import moment from "moment";

export const apiService = {
  login,
  addprediction,
  updateprediction,
  get_prediction,
  get_predictionDetail,
  Dashboard,
  ChangeStatus,
  changePassword,
  get_users,
  update_status,
  transaction_list,
  contactUs,
  getBalance,
  getFundBalance,
  getTokenwithContactAddress,
  getContactNameByAddress,
  checkbalanceInWallet,
  getSoloPredictions,
  addSoloPredictions,
  updateSoloPredictions,
  changeSoloStatus,
  getSoloDetail,
  getGroupTemplates,
  getGroupTemplateDetail,
  updateGroupTemplate,
  getBonusPoint,
  generateCSVBet,
  nftseedDatabase,
  getMultiPrediction,
  get_multi_predictionDetail,
  updateMultiPredictionTemplate,
  get_winning_predictions,
  get_winning_predictions_only_amount,
  pin_unpin_prediction,
  get_failed_transations_list,
  get_executable_transations_list,
  getWhitelistContracts,
  multichoicePredictionLaunch,
  dailyGroupPredictionCount,
  dailyGroupPredictionCron,
  folioGroupPredictionCount,
  folioGroupPredictionCron,
  soloPredictionCount,
  soloPredictionCron,
  transaction_status_change,
  redeemListData,
  getFreeGroupPrediction,
  get_freeGroup_predictionDetail,
  addFreeGroupPredictions,
  gerResult_failed_transations_list,
  result_transaction_status_change,
  resultFiveDayStatus
};

const headers = () => {
  let token = getItem("admin_token");
  return { headers: { Authorization: `${token}` } };
};

//    console.log('headers()....', headers());
async function login(data) {
  return await axios.post(`${apiUrl}/admin/admin-login/`, data);
}

const convertToExponential = (amount, decimal) => {
  console.log("calcuate_amount convertToExponential", amount, decimal);
  const multiplier = 10 ** Number(decimal);
  return parseFloat(amount) * multiplier;
};

async function addprediction(params, image, startDate) {

  let data = new FormData();
  let timestamp = toTimestamp(startDate);
  data.append("prediction_type", params.prediction_type);
  data.append("question", params?.question?.trimEnd());
  data.append("data_provider", params?.data_provider)
  if (params.prediction_type == "RISK-FREE") {
    console.log("calcuate_amount in last", params.amount);
    let amountToSend = convertToExponential(params.amount, params.decimal)
    console.log("calcuate_amount amount", amountToSend);
    data.append("amount", amountToSend);
    data.append("chain_name", params?.chain_name)
    data.append("symbol", params.symbol)
    data.append("chain_type", "MATIC")
    data.append("decimal", params?.decimal)
    console.log("accessSetting >>>>>>>", params.accessSetting, parseInt(params.accessSetting));
    data.append("access_setting", parseInt(params.accessSetting))

    if (parseInt(params.accessSetting) == 3 || parseInt(params.accessSetting) == 5) {
      data.append("nft_contract_address", params.nftContractAddress)
    } else {
      data.append("nft_contract_address", "0x0000000000000000000000000000000000000000")
    }

    if (parseInt(params.accessSetting) == 4 || parseInt(params.accessSetting) == 6) {
      data.append("access_setting_token_address", params.accessSettingTokenAddress)
      let accessSettingTokenAmountToSend = convertToExponential(params.accessSettingTokenAmount, params.accessSettingTokenDecimal)
      console.log("calcuate_amount amount", accessSettingTokenAmountToSend);
      data.append("access_setting_token_amount", accessSettingTokenAmountToSend);
      data.append("access_setting_token_name", params.accessSettingTokenName);
      data.append("access_setting_token_symbol", params.accessSettingTokenSymbol);
      data.append("access_setting_token_decimal", params.accessSettingTokenDecimal);


    } else {
      data.append("access_setting_token_address", "0x0000000000000000000000000000000000000000")
      data.append("access_setting_token_amount", 0)
      data.append("access_setting_token_name", params.accessSettingTokenName);
      data.append("access_setting_token_symbol", params.accessSettingTokenSymbol);
      data.append("access_setting_token_decimal", 0);
    }
    data.append("disclaimer", params.disclaimer)


    data.append("nft_name", params.nftContractAddressName);
    data.append("nft_symbol", params.nftContractAddressSymbol);

    data.append("token_address", params.token_address ? params.token_address : tokenAddress)

  }

  if (params.prediction_type == "PAID") {
    data.append("amount", 0);
    // data.append("chain_name", params?.chain_name)
    data.append("symbol", "USDC")
    data.append("chain_type", "MATIC")
    data.append("decimal", 6)

    data.append("chain_name",'USDC Coin');
    data.append("access_setting",1);
    data.append("nft_contract_address",'0x0000000000000000000000000000000000000000');
    data.append("access_setting_token_address",'0x0000000000000000000000000000000000000000');
    data.append("access_setting_token_amount",'0');
    data.append("access_setting_token_name",'');
    data.append("access_setting_token_symbol",'');
    data.append("access_setting_token_decimal",'0');
    data.append("disclaimer",'');
    data.append("nft_name",'');
    data.append("nft_symbol",'');
    // data.append("token_address",'0x5E6168E0B90Ec559CF0376c4BFDCa4a5689Ae943');
    data.append("token_address",  tokenAddress);

  }


  data.append("description", params?.description?.trimEnd());
  data.append("startDateTime", timestamp);
  data.append("duration", params.duration);
  data.append("threshold_time", params.threshold_time);
  data.append("outcome", params.outcome);
  data.append("image", image);
  data.append("canBet", params.canBet);
  data.append("whitelistWalletAddress", JSON.stringify(params.specificWallets));

  data.append("platformWalletAddress", JSON.stringify(params.commissionData));
  data.append("prediction_category", params.prediction_category);



  if (params.prediction_category == "REGULAR") {
    data.append("answer_option", JSON.stringify(["True", "False"]));
  } else {
    data.append("answer_option", JSON.stringify(params.answerOption));
  }
  return await axios.post(`${apiUrl}/admin/add-prediction/`, data, headers());
}
async function updateprediction(params, id, image, startDate) {
  let data = new FormData();
  console.log("======startDate===", startDate);
  let timestamp = toTimestamp(startDate);
  data.append("amount", params.amount);
  data.append("question", params.question?.trimEnd());
  data.append("description", params.description?.trimEnd());
  data.append("startDateTime", timestamp);
  data.append("duration", params.duration);
  data.append("threshold_time", params.threshold_time);
  data.append("outcome", params.outcome);
  data.append("whitelistWalletAddress", JSON.stringify(params.specificWallets));
  data.append("platformWalletAddress", JSON.stringify(params.commissionData));
  data.append("disclaimer", params.disclaimer)
  data.append("data_provider", params?.data_provider)


  console.log("======image=====", image);
  if (image != undefined) {
    console.log("======image=====", image);
    data.append("image", image);
  }
  return await axios.put(
    `${apiUrl}/admin/edit-prediction/` + id,
    data,
    headers()
  );
}
const toTimestamp = (strDate) => {
  const dt = moment.utc(strDate).unix();
  return dt;
};
async function get_prediction(page, type, duration, prediction) {
  console.log("page, type, duration, prediction=========", page, type, duration, prediction)
  return await axios.get(
    `${apiUrl}/admin/prediction-list?page=` +
    page +
    `&duration=` +
    duration +
    `&type=` +
    type +
    `&prediction_type=` +
    prediction,
    headers()
  );
}

async function addSoloPredictions(data) {
  return await axios.post(
    `${apiUrl}/admin/solo-predictions/`,
    data,
    headers()
  );
}

async function updateSoloPredictions(data, id) {
  return await axios.put(
    `${apiUrl}/admin/solo-predictions/` + id,
    data,
    headers()
  );
}

async function updateGroupTemplate(data,id){
  return await axios.put(
    `${apiUrl}/admin/group-templates/` + id,
    data,
    headers()
  );

}

async function getBonusPoint(){
  return await axios.get(`${apiUrl}/user/get-bonus-point`, headers())
}

async function changeSoloStatus(data, id) {
  return await axios.put(
    `${apiUrl}/admin/solo-predictions/${id}/enable-disable`,
    data,
    headers()
  );
}

async function getSoloPredictions(page,soloType) {
  return await axios.get(
    `${apiUrl}/admin/solo-predictions?page=` + page + "&type="+ soloType,
    headers()
  );
}

async function getGroupTemplates(page) {
  return await axios.get(
    `${apiUrl}/admin/group-templates?page=` +
    page,
    headers()
  );
}


async function get_predictionDetail(id) {
  return await axios.get(`${apiUrl}/admin/prediction-detail/` + id, headers());
}
async function getSoloDetail(id) {
  return await axios.get(`${apiUrl}/admin/solo-predictions/` + id, headers());
}

async function getGroupTemplateDetail(id){
  return await axios.get(`${apiUrl}/admin/group-templates/` + id, headers());
}

async function changePassword(data) {
  return await axios.put(`${apiUrl}/admin/change-password/`, data, headers());
}

async function get_users(page, search) {
  return await axios.get(
    `${apiUrl}/admin/usersList?page=` + page + "&search=" + search,
    headers()
  );
}
async function update_status(id, data) {
  return await axios.put(`${apiUrl}/admin/user-status/${id}`, data, headers());
}
async function transaction_list(page, address, walletaddress) {
  return await axios.get(
    `${apiUrl}/admin/transaction-list?page=` +
    page +
    "&contract_address=" +
    address +
    "&wallet_address=" +
    walletaddress,
    headers()
  );
}

async function Dashboard() {
  return await axios.get(`${apiUrl}/admin/dashboard`, headers());
}
async function ChangeStatus(id, params) {
  return await axios.put(
    `${apiUrl}/change_user_status/${id}/`,
    params,
    headers()
  );
}

async function contactUs(page) {
  return await axios.get(
    `${apiUrl}/admin/contactUs-list?page=` + page,
    headers()
  );
}

// async function redeemListData(page,searchTerm,statusFilter) {
//   return await axios.get(
//     `${apiUrl}/admin/get-redeem-requests?page=` + page +  "&wallet_address=" + searchTerm+  "&status=" + statusFilter,
//     headers()
//   );
// }
async function redeemListData(page, searchTerm, statusFilter) {
  // Construct the base URL
  let url = `${apiUrl}/admin/get-redeem-requests?page=` + page;

  // Append searchTerm and statusFilter only if they have values
  if (searchTerm) {
    url += `&wallet_address=${searchTerm}`;
  }
  if (statusFilter) {
    url += `&status=${statusFilter}`;
  }

  // Make the API call
  return await axios.get(url, headers());
}



async function getBalance() {
  return await axios.get(`${apiUrl}/admin/getBalance`, headers());
}
async function getFundBalance() {
  return await axios.get(`${apiUrl}/admin/getFundBalance`, headers());
}

async function getTokenwithContactAddress(token) {
  return await axios.get(`${apiUrl}/admin/getSymbolAndName/${token}`, headers());
}

async function getContactNameByAddress(token) {
  return await axios.get(`${apiUrl}/admin/getNftName/${token}`, headers());
}

async function checkbalanceInWallet(walletAddress) {
  return await axios.get(`${apiUrl}/admin/getTokenBalance/${walletAddress}`, headers());
}

async function generateCSVBet() {
  return await axios.get(`${apiUrl}/admin/generateCSVBet/`, headers());
}
async function nftseedDatabase() {
  return await axios.get(`${apiUrl}/admin/nftseedDatabase/`, headers());
}

async function getMultiPrediction(page,soloType,search) {
  return await axios.get(`${apiUrl}/admin/multi-choice-predictions?page=${page}&type=${soloType}&title=${search}`,headers());
}


async function get_multi_predictionDetail(id) {
  return await axios.get(`${apiUrl}/admin/multi-choice-predictions/` + id, headers());
}
async function updateMultiPredictionTemplate(data,id){
  return await axios.put(
    `${apiUrl}/admin/multi-choice-predictions/` + id,
    data,
    headers()
  );

}

async function get_winning_predictions(params) {
  return await axios.get(`${apiUrl}/admin/get-bet-wons?page=${params.page}&soloType=${params.soloType}&startDate=${params.startDate}&endDate=${params.endDate}`, headers());
}

async function get_winning_predictions_only_amount(params) {
  return await axios.get(`${apiUrl}/admin/get-winning-predictions-amounts?page=${params.page}&soloType=${params.soloType}&startDate=${params.startDate}&endDate=${params.endDate}`, headers());
}


async function pin_unpin_prediction(data,id){
  return await axios.put(
    `${apiUrl}/admin/update-ispin/` + id,
    data,
    headers()
  );
};


async function get_failed_transations_list(params) {
  return await axios.get(`${apiUrl}/admin/get-failed-transactions?page=${params.page}&status=${params.status}`, headers());
};


async function get_executable_transations_list(params) {
  return await axios.get(`${apiUrl}/admin/get-failed-transactions-can-relaunch?page=${params.page}`, headers());
};


async function getWhitelistContracts(params) {
  return await axios.get(`${apiUrl}/admin/whitelist-contracts?page=${params.page}`, headers());
};


async function multichoicePredictionLaunch(templateId) {
  return await axios.get(`${apiUrl}/admin/multichoice-prediction-launch/${templateId}`, headers());
};

async function dailyGroupPredictionCount(day) {
  return await axios.get(`${apiUrl}/admin/daily-group-count/${day}`, headers());
};

async function dailyGroupPredictionCron(day) {
  return await axios.get(`${apiUrl}/admin/daily-group-cron/${day}`, headers());
};

async function folioGroupPredictionCount(day) {
  return await axios.get(`${apiUrl}/admin/folio-group-count/${day}`, headers());
};

async function folioGroupPredictionCron(day) {
  return await axios.get(`${apiUrl}/admin/folio-group-cron/${day}`, headers());
};

async function soloPredictionCount(type) {
  return await axios.get(`${apiUrl}/admin/solo-count/${type}`, headers());
};

async function soloPredictionCron(type) {
  return await axios.get(`${apiUrl}/admin/solo-cron/${type}`, headers());
};

async function transaction_status_change(transactionIds,status) {
  return await axios.put(`${apiUrl}/admin/update-failed-transactions`, {status,transactionIds}, headers());
};

/* free group prediction */
// async function getFreeGroupPrediction(page,soloType) {
//   return await axios.get(
//     `${apiUrl}/admin/multi-choice-predictions?page=` +
//     page+ "&type="+ soloType,
//     headers()
//   );
// }

async function getFreeGroupPrediction(page) {
  return await axios.get(
    `${apiUrl}/admin/group-templates-folio?page=` +
    page+ "&type=",
    headers()
  );
}

async function get_freeGroup_predictionDetail(id) {
  return await axios.get(`${apiUrl}/admin/group-templates-folio/` + id, headers());
}

async function addFreeGroupPredictions(data, id) {
  return await axios.put(
    `${apiUrl}/admin/group-templates-folio/` + id,
    data,
    headers()
  );
}

async function gerResult_failed_transations_list(params) {
  return await axios.get(`${apiUrl}/admin/result-failed-predictions?page=${params.page}`, headers());
}

async function result_transaction_status_change(transactionIds,status) {
  return await axios.put(`${apiUrl}/admin/update-failed-result-predictions/${transactionIds}`, {status,transactionIds}, headers());
};

async function resultFiveDayStatus() {
  return await axios.get(`${apiUrl}/admin/get-winning-predictions-amounts-last-five-days`, headers());
};